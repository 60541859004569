import React from "react";
import {connect} from "react-redux"

import * as actions from "../actions"

const MaterialsPage = ({controls, materials, controlUpdateAct, controlFocusLostAct, materialsAddAct, plywoodConsumptionAct, crudUpdateItemAct2}) => {
    return (
        <main className="container-fluid">
            <div className="col pb-2 d-inline-block">
                <h1>Материалы</h1>
            </div>
            <div className="row pb-3 bg-light">
                <div className="col-6">
                    <label htmlFor="addMaterial" className="text-nowrap">Материал</label>
                    <select id="addMaterial" name="addMaterial" value={controls.addMaterial.name} className="custom-select custom-select-sm"
                            onChange={(e) => controlUpdateAct([e.target.name], e.target.value, materials)}>
                        {!materials ? "" : materials.map((material, i) => {
                            return <option key={i} value={material.name}>{material.name}</option>
                        })}
                    </select>
                </div>
                <div className="col-4">
                    <label htmlFor="addMaterialCount">Кол-во</label>
                    <input id="addMaterialCount" name="addMaterialCount" type="number" value={controls.addMaterialCount} className="form-control form-control-sm"
                            onChange={(e) => controlUpdateAct([e.target.name], e.target.value)}
                            onBlur={(e) => controlFocusLostAct([e.target.name], e.target.value)}
                    />
                </div>
                <div className="col-2">
                    <br/>
                    <button className="btn btn-primary btn-sm mt-2" onClick={materialsAddAct}>Добавить</button>
                </div>
            </div>
            <div className="row pb-3 bg-light">
                <div className="col-6">
                    <label htmlFor="plywoodConsumption">Расход фанеры</label>
                    <input id="plywoodConsumption" name="plywoodConsumption" type="number" value={controls.plywoodConsumption} min="1" className="form-control form-control-sm"
                           onChange={(e) => controlUpdateAct([e.target.name], e.target.value)}
                           onBlur={(e) => controlFocusLostAct([e.target.name], e.target.value)}/>
                </div>
                <div className="col-2">
                    <br/>
                    <button className="btn btn-primary btn-sm mt-2" onClick={plywoodConsumptionAct}>Добавить</button>
                </div>
            </div>
            <table className="table border-bottom">
                <thead>
                    <tr className="row bg-secondary">
                        <th className="col">Наименование</th>
                        <th className="col-3">Кол-во</th>
                        <th className="col-3">Стоимость</th>
                    </tr>
                </thead>
                <tbody>
                {!materials ? <tr><td>&nbsp;</td></tr> : materials.map((material, i) => {
                    return <tr key={i} className={`row ${material.count <= material.alertCount ? " bg-danger" : ""}`}>
                        <td className="col">{material.name}</td>
                        <td className="col-3">{material.count ? material.count.toFixed(2).replace(/[.,]00$/, "") : 0}</td>
                        <td className="col-3">
                            <input id={`cost-${i}`} name="price" type="number" value={material.price} min="0" className="form-control form-control-sm"
                                   onChange={(e) => crudUpdateItemAct2("name", material.name, "materials", [e.target.name], e.target.value)}/>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </main>
    )
}

const mapStateToProps = (state) => {
    return {controls: state.controls, materials: state.data.materials};
}

export default connect(mapStateToProps, actions)(MaterialsPage)
