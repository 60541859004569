import React from "react";
import {connect} from "react-redux"

import * as actions from "../actions"
import OrdersRow from "./OrdersRow";

const OrdersPage = ({orders, requiredFieldsError, ordersAddRowAct, ordersRemoveAct, ordersMoveToWorkingAct}) => {
    const selected = !orders ? 0 : orders.some(order => order.selected);
    return (
        <main className="container-fluid">
            <div className="col pb-2 d-inline-block">
                <h1>Заказы</h1>
            </div>
            <table className="table">
                <tbody>
                {!orders ? <tr><td>&nbsp;</td></tr> : orders.filter(ord => !ord.working).map((order, i) => {
                    return <OrdersRow key={order.id} order={order} category="orders"/>
                })}
                </tbody>
            </table>
            {requiredFieldsError ? <div className="alert alert-danger text-center" role="alert">Заполните все обязательные поля</div> : ""}
            <div className="row">
                <div className="col-6 col-md pb-2 d-inline-block mt-1">
                    <button className="btn btn-dark btn-sm text-nowrap" onClick={ordersAddRowAct}>Добавить заказ</button>
                </div>
                <div className="col-6 col-md-auto">
                    <button className="btn btn-primary btn-sm ml-1 mt-1" disabled={!selected} onClick={ordersRemoveAct}>Удалить</button>
                    <button className="btn btn-primary btn-sm ml-1 mt-1" disabled={!selected} onClick={() => ordersMoveToWorkingAct(true)}>В работу</button>
                </div>
            </div>
        </main>
    )
}

const mapStateToProps = (state) => {
    return {orders: state.data.orders, requiredFieldsError: state.requiredFieldsError};
}

export default connect(mapStateToProps, actions)(OrdersPage);
