import React from "react"
import {connect} from "react-redux"
import {
    pdf,
    Document,
    Page,
    Text,
    View,
    // PDFViewer,
    StyleSheet
} from "@react-pdf/renderer";
import { saveAs } from 'file-saver';
import * as actions from "../actions";

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        fontSize: 10,
    },
    header: {
        fontSize: 16,
        marginTop: "10px",
        textAlign: "center"
    },
    table: {
        display: "table",
        width: "auto",
        marginLeft: "10px",
        marginTop: "10px",
        borderStyle: "solid",
        borderLeftWidth: 1,
    },
    tableRow: {
        flexDirection: "row",
    },
    dataHeader: {
        textAlign: "center",
        backgroundColor: "#ccc",
        padding: 2,
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 1
    },
    dataCol: {
        padding: 2,
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    }
});

const PaintingPdfDocument = ({rows, date}) => {
    return (!rows ? "" :
            <Document>
                <Page size="A4" orientation="portrait" style={styles.page}>
                    <View style={styles.header}>
                        <Text>Задание на покраску {date}</Text>
                    </View>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={{...styles.dataHeader, width: "30px"}}>№</Text>
                            <Text style={{...styles.dataHeader, width: "250px"}}>Наименование</Text>
                            <Text style={{...styles.dataHeader, width: "170px"}}>Цвет</Text>
                            <Text style={{...styles.dataHeader, width: "100px"}}>Кол-во</Text>
                        </View>
                        {rows.map((row, index) => {
                            return (
                                <View key={index} style={styles.tableRow}>
                                    <Text style={{...styles.dataCol, width: "30px"}}>{index + 1}</Text>
                                    <Text style={{...styles.dataCol, width: "250px"}}>{row.name}</Text>
                                    <Text style={{...styles.dataCol, width: "170px"}}>{row.color}</Text>
                                    <Text style={{...styles.dataCol, width: "100px"}}>{row.count}</Text>
                                </View>
                            );
                        })}
                    </View>
                </Page>
            </Document>
    );
};

const PaintingPrint = ({rows}) => {
    const date = new Date().toLocaleDateString();
    if (rows && rows.length > 0) {
        return (
            <React.Fragment>
                {/*<PDFViewer style={{width: "1400px", height: "900px"}}>*/}
                {/*    <PaintingPdfDocument rows={rows} date={date}/>*/}
                {/*</PDFViewer>*/}
                <button className="btn btn-primary btn-sm ml-1" onClick={async () => {
                    const document = <PaintingPdfDocument rows={rows} date={date}/>
                    const asPdf = pdf([]);
                    asPdf.updateContainer(document);
                    const blob = await asPdf.toBlob();
                    saveAs(blob, `Задание на покраску ${date}.pdf`);
                }}>Задание на покраску PDF</button>
            </React.Fragment>
        );
    }
    return <button className="btn btn-primary btn-sm ml-1" disabled={true}>Задание на покраску PDF</button>
};

const mapStateToProps = (state) => {
    return {rows: state.paintingRows};
}

export default connect(mapStateToProps, actions)(PaintingPrint);
