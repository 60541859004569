
export const loginGetAct = (auth) => ({type: 'LOGIN_GET', auth: auth});
export const loginErrorAct = (error) => ({type: 'LOGIN_ERROR', error: error});
export const loginDataUpdateAct = (prop, value) => ({type: 'LOGIN_DATA_UPDATE', prop: prop, value: value});
export const loginClickAct = (authData, apiService) => (dispatch) => {
    apiService.doLogin(authData.login, authData.pass)
        .then(resp => dispatch({type: 'LOGIN_SUCCESS', auth: resp.data.auth}))
        .catch(error => dispatch({type: 'LOGIN_ERROR', error: error}))
};
export const submitChangesAct = (data, apiService) => (dispatch) => {
    apiService.submitChanges(data)
        .then(resp => dispatch({type: 'SUBMIT_SUCCESS', auth: resp.data.auth, done: resp.data.done}))
        .catch(error => dispatch({type: 'SUBMIT_ERROR', error: error}))
};
export const closeSessionAct = (login, apiService) => (dispatch) => {
    apiService.closeSession(login)
        .then(resp => dispatch(loginGetAct(resp.data.auth)))
        .catch(error => console.error(error))
};

export const crudDeleteItemAct = (id, category) => ({type: 'CRUD_DELETE', id: id, category: category});
export const crudUpdateItemAct = (id, category, prop, value, dataList) => ({type: 'CRUD_UPDATE', id: id, category: category, prop: prop, value: value, dataList: dataList});
export const crudUpdateItemAct2 = (idField, id, category, prop, value, dataList) => ({type: 'CRUD_UPDATE2', idField: idField, id: id, category: category, prop: prop, value: value, dataList: dataList});
export const crudUpdateItemFocusLostAct = (id, category, prop, value, defaultValue) => ({type: "CRUD_UPDATE_FOCUS_LOST", id: id, category: category, prop: prop, value: value, defaultValue: defaultValue});
export const controlUpdateAct = (prop, value, dataList) => ({type: 'CONTROL_UPDATE', prop: prop, value: value, dataList: dataList});
export const controlFocusLostAct = (prop, value, defaultValue) => ({type: 'CONTROL_FOCUS_LOST', prop: prop, value: value, defaultValue: defaultValue});

export const ordersRefLoadedAct = (ref) => ({type: "ORDERS_REF_LOADED", ref: ref});
export const ordersLoadedAct = (data) => ({type: "ORDERS_LOADED", data: data});
export const ordersLoadErrorAct = (dataType, error) => ({type: "ORDERS_LOAD_ERROR", dataType: dataType, error: error});

export const ordersAddRowAct = () => ({type: "ORDERS_ADD_ROW"});
export const ordersRemoveAct = () => ({type: "ORDERS_REMOVE"});
export const ordersMoveToWorkingAct = (isWorking) => ({type: "ORDERS_MOVE_TO_WORKING", isWorking: isWorking});
export const ordersMoveToFinishAct = () => ({type: "ORDERS_MOVE_TO_FINISH"});
export const ordersRemoveFinishedAct = (month) => ({type: "ORDERS_REMOVE_FINISHED", month: month});
export const workingCalculatePaintingAct = () => ({type: "WORKING_CALCULATE_PAINTING"});

export const materialsAddAct = () => ({type: "MATERIALS_ADD"});
export const plywoodConsumptionAct = () => ({type: "PLYWOOD_CONSUMPTION"});
