import React from "react";
import {connect} from "react-redux"

import * as actions from "../actions"

const OrdersRow = ({order, category, refs, crudUpdateItemAct, crudUpdateItemFocusLostAct}) => {
    return (
        <tr className={`row row-cols-lg-6 row-cols-sm-3 row-cols-2${order.urgent ? " bg-warning" : ""}`}>
            <td className="col">
                <div className="mt-2">
                    <label htmlFor={`date-${order.id}`}>Дата</label>
                    <input id={`date-${order.id}`} name="date" type="date" value={order.date ? order.date.toISOString().substring(0, 10) : ""} className="form-control form-control-sm"
                           onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.value)}
                    />
                    <div className="invalid-feedback my-0"><small>Обязательное поле</small></div>
                </div>
                <div className="mt-2">
                    <label htmlFor={`size-${order.id}`}>Размер</label>
                    <select id={`size-${order.id}`} name="size" value={order.size.name} className="custom-select custom-select-sm"
                            onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.value, refs.sizes)}>
                        {refs.sizes.map((size, i) => {
                            return <option key={i} value={size.name}>{size.name}</option>
                        })}
                    </select>
                </div>
                <div className="mt-2">
                    <label htmlFor={`quantity-${order.id}`}>Кол-во</label>
                    <input id={`quantity-${order.id}`} name="quantity" type="number" value={order.quantity} min="1" className="form-control form-control-sm"
                           onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.value)}
                           onBlur={(e) => crudUpdateItemFocusLostAct(order.id, category, [e.target.name], e.target.value, 1)}/>
                </div>
            </td>
            <td className="col">
                <div className="mt-2">
                    <label htmlFor={`kit-${order.id}`}>Комплект</label>
                    <select id={`kit-${order.id}`} name="kit" value={order.kit.name} className="custom-select custom-select-sm"
                            onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.value, refs.kits)}>
                        {refs.kits.map((kit, i) => {
                            return <option key={i} value={kit.name}>{kit.name}</option>
                        })}
                    </select>
                </div>
                <div className="mt-2">
                    <label htmlFor={`colorBase-${order.id}`}>Основа</label>
                    <select id={`colorBase-${order.id}`} name="colorBase" value={order.colorBase.name} className="custom-select custom-select-sm"
                            onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.value, refs.colorsBase)}>
                        {refs.colorsBase.map((color, i) => {
                            return <option key={i} value={color.name}>{color.name}</option>
                        })}
                    </select>
                </div>
                <div className="mt-2">
                    <label htmlFor={`colorDetails-${order.id}`}>Нагелея</label>
                    <select id={`colorDetails-${order.id}`} name="colorDetails" value={order.colorDetails.name} className="custom-select custom-select-sm"
                            onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.value, refs.colorsDetails)}>
                        {refs.colorsDetails.map((color, i) => {
                            return <option key={i} value={color.name}>{color.name}</option>
                        })}
                    </select>
                </div>
            </td>
            <td className="col">
                {order.kit.ramps < 1 ? "" :
                    <React.Fragment>
                        <div className="mt-2">
                            <label htmlFor={`ramp-${order.id}`} className="text-nowrap">Наполнение рампы</label>
                            <select id={`ramp-${order.id}`} name="ramp" value={order.ramp.name} className="custom-select custom-select-sm"
                                    onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.value, refs.ramps)}>
                                {refs.ramps.map((ramp, i) => {
                                    return <option key={i} value={ramp.name}>{ramp.name}</option>
                                })}
                            </select>
                        </div>
                        <div className="mt-2">
                            <label htmlFor={`rampColorBase-${order.id}`} className="text-nowrap">Основа рампы</label>
                            <select id={`rampColorBase-${order.id}`} name="rampColorBase" value={order.rampColorBase.name} className="custom-select custom-select-sm"
                                    onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.value, refs.colorsBase)}>
                                {refs.colorsBase.map((color, i) => {
                                    return <option key={i} value={color.name}>{color.name}</option>
                                })}
                            </select>
                        </div>
                        <div className="mt-2">
                            <label htmlFor={`rampColorDetails-${order.id}`} className="text-nowrap">Детали рампы</label>
                            <select id={`rampColorDetails-${order.id}`} name="rampColorDetails" value={order.rampColorDetails.name} className="custom-select custom-select-sm"
                                    onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.value, refs.colorsDetails)}>
                                {refs.colorsDetails.map((color, i) => {
                                    return <option key={i} value={color.name}>{color.name}</option>
                                })}
                            </select>
                        </div>
                    </React.Fragment>
                }
            </td>
            <td className="col">
                {order.kit.ramps < 2 ? "" :
                    <React.Fragment>
                        <div className="mt-2">
                            <label htmlFor={`ramp2-${order.id}`} className="text-nowrap">Наполнение рампы 2</label>
                            <select id={`ramp2-${order.id}`} name="ramp2" value={order.ramp2.name} className="custom-select custom-select-sm"
                                    onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.value, refs.ramps)}>
                                {refs.ramps.map((ramp, i) => {
                                    return <option key={i} value={ramp.name}>{ramp.name}</option>
                                })}
                            </select>
                        </div>
                        <div className="mt-2">
                            <label htmlFor={`rampColorBase2-${order.id}`} className="text-nowrap">Основа рампы 2</label>
                            <select id={`rampColorBase2-${order.id}`} name="rampColorBase2" value={order.rampColorBase2.name} className="custom-select custom-select-sm"
                                    onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.value, refs.colorsBase)}>
                                {refs.colorsBase.map((color, i) => {
                                    return <option key={i} value={color.name}>{color.name}</option>
                                })}
                            </select>
                        </div>
                        <div className="mt-2">
                            <label htmlFor={`rampColorDetails2-${order.id}`} className="text-nowrap">Детали рампы 2</label>
                            <select id={`rampColorDetails2-${order.id}`} name="rampColorDetails2" value={order.rampColorDetails2.name} className="custom-select custom-select-sm"
                                    onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.value, refs.colorsDetails)}>
                                {refs.colorsDetails.map((color, i) => {
                                    return <option key={i} value={color.name}>{color.name}</option>
                                })}
                            </select>
                        </div>
                    </React.Fragment>
                }
            </td>
            <td className="col">
                <div className="mt-2">
                    <label htmlFor={`orderNum-${order.id}`}>№&nbsp;заказа<span className="text-danger">*</span></label>
                    <input id={`orderNum-${order.id}`} name="orderNum" type="text" value={order.orderNum} className={`form-control form-control-sm${order.selected && !order.orderNum ? " is-invalid" : ""}`}
                           onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.value)}/>
                    <div className="invalid-feedback my-0"><small>Обязательное поле</small></div>
                </div>
                <div className="mt-2">
                    <label htmlFor={`notes-${order.id}`}>Примечание</label>
                    <textarea id={`notes-${order.id}`} name="notes" rows="3" value={order.notes} className="form-control form-control-sm"
                              onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.value)}/>
                </div>
                <div className="mt-1">
                    <label htmlFor={`urgent-${order.id}`} className="text-nowrap">Срочно&nbsp;</label>
                    <input id={`urgent-${order.id}`} name="urgent" type="checkbox" checked={order.urgent}
                           onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.checked)}/>
                </div>
            </td>
            <td className="col">
                <div className="mt-2 text-right">
                    <label htmlFor={`selected-${order.id}`} className="text-nowrap">Выбрать&nbsp;</label>
                    <input id={`selected-${order.id}`} name="selected" type="checkbox" checked={order.selected}
                           onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.checked)}/>
                </div>
            </td>
        </tr>
    )
}

const mapStateToProps = (state) => {
    return {refs: state.ref}
}

export default connect(mapStateToProps, actions)(OrdersRow)
