import React from "react";
import {connect} from "react-redux"
import {
    pdf,
    Document,
    Page,
    Text,
    View,
    // PDFViewer,
    StyleSheet
} from "@react-pdf/renderer";
import { saveAs } from 'file-saver';

import * as actions from "../actions"

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        fontSize: 10,
    },
    header: {
        fontSize: 16,
        marginTop: "10px",
        textAlign: "center"
    },
    table: {
        display: "table",
        width: "auto",
        marginLeft: "10px",
        marginTop: "10px",
        borderStyle: "solid",
        borderLeftWidth: 1,
    },
    tableRow: {
        flexDirection: "row",
    },
    dataHeader: {
        textAlign: "center",
        backgroundColor: "#ccc",
        padding: 2,
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 1
    },
    dataCol: {
        padding: 2,
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    }
});

const PackingPdfDocument = ({orders, date}) => {
    return (!orders ? "" :
            <Document>
                <Page size="A4" orientation="landscape" style={styles.page}>
                    <View style={styles.header}>
                        <Text>Задание на упаковку {date}</Text>
                    </View>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={{...styles.dataHeader, width: "20px"}}>№</Text>
                            <Text style={{...styles.dataHeader, width: "150px"}}>Комплект</Text>
                            <Text style={{...styles.dataHeader, width: "25px"}}>К-во</Text>
                            <Text style={{...styles.dataHeader, width: "50px"}}>Основа</Text>
                            <Text style={{...styles.dataHeader, width: "50px"}}>Нагелея</Text>
                            <Text style={{...styles.dataHeader, width: "60px"}}>Размер</Text>
                            <View>
                                <Text style={{...styles.dataHeader, textAlign: "center", width: "160px"}}>Рампа</Text>
                                <View style={styles.tableRow}>
                                    <Text style={{...styles.dataHeader, borderTopWidth: 0, width: "60px"}}>Наполнение</Text>
                                    <Text style={{...styles.dataHeader, borderTopWidth: 0, width: "50px"}}>Основа</Text>
                                    <Text style={{...styles.dataHeader, borderTopWidth: 0, width: "50px"}}>Детали</Text>
                                </View>
                            </View>
                            <Text style={{...styles.dataHeader, width: "60px"}}>Дата</Text>
                            <Text style={{...styles.dataHeader, width: "70px"}}>№ заказа</Text>
                            <Text style={{...styles.dataHeader, width: "175px"}}>Примечание</Text>
                        </View>
                        {orders.map((order, index) => {
                            return (
                                <View key={index} style={styles.tableRow}>
                                    <Text style={{...styles.dataCol, width: "20px"}}>{index + 1}</Text>
                                    <Text style={{...styles.dataCol, width: "150px"}}>{order.kit.name}</Text>
                                    <Text style={{...styles.dataCol, width: "25px"}}>{order.quantity}</Text>
                                    <Text style={{...styles.dataCol, width: "50px"}}>{order.colorBase.name}</Text>
                                    <Text style={{...styles.dataCol, width: "50px"}}>{order.colorDetails.name}</Text>
                                    <Text style={{...styles.dataCol, width: "60px"}}>{order.size.name}</Text>
                                    <Text style={{...styles.dataCol, width: "60px"}}>{order.kit.ramps < 1 ? "" : order.ramp.name + (order.kit.ramps === 2 ? "\n" + order.ramp2.name : "")}</Text>
                                    <Text style={{...styles.dataCol, width: "50px"}}>{order.kit.ramps < 1 ? "" : order.rampColorBase.name + (order.kit.ramps === 2 ? "\n" + order.rampColorBase2.name : "")}</Text>
                                    <Text style={{...styles.dataCol, width: "50px"}}>{order.kit.ramps < 1 ? "" : order.rampColorDetails.name + (order.kit.ramps === 2 ? "\n" + order.rampColorDetails2.name : "")}</Text>
                                    <Text style={{...styles.dataCol, width: "60px"}}>{order.date ? order.date.toLocaleDateString() : ""}</Text>
                                    <Text style={{...styles.dataCol, width: "70px"}}>{order.orderNum}</Text>
                                    <Text style={{...styles.dataCol, width: "175px"}}>{order.notes}</Text>
                                </View>
                            );
                        })}
                    </View>
                </Page>
            </Document>
    );
};

const PackingPrint = ({orders}) => {
    if (orders) {
        const workingOrders = orders.filter(ord => ord.working);
        if (workingOrders.length > 0) {
            const date = new Date().toLocaleDateString();
            return (
                <React.Fragment>
                    {/*<PDFViewer style={{width: "1400px", height: "900px"}}>*/}
                    {/*    <PackingPdfDocument orders={orders} date={date}/>*/}
                    {/*</PDFViewer>*/}
                    <button className="btn btn-primary btn-sm ml-1" onClick={async () => {
                        const doc = <PackingPdfDocument orders={workingOrders} date={date}/>
                        const asPdf = pdf([]);
                        asPdf.updateContainer(doc);
                        const blob = await asPdf.toBlob();
                        saveAs(blob, `Задание на упаковку ${date}.pdf`);
                    }}>Задание на упаковку PDF</button>
                </React.Fragment>
            )
        }
    }
    return <button className="btn btn-primary btn-sm ml-1" disabled={true}>Задание на упаковку PDF</button>
}

const mapStateToProps = (state) => {
    return {orders: state.data.orders};
}

export default connect(mapStateToProps, actions)(PackingPrint);
