import React from "react";
import {connect} from "react-redux"

import * as actions from "../actions"

const FinishedRow = ({order, category, crudUpdateItemAct, crudUpdateItemFocusLostAct}) => {
    return (
        <tr className={`row row-cols-lg-6 row-cols-sm-3 row-cols-2${order.urgent ? " bg-warning" : ""}`}>
            <td className="col">
                <div className="font-weight-bold">Дата&nbsp;заказа</div>
                <div>{order.date ? order.date.toLocaleDateString() : ""}</div>
                <div className="font-weight-bold">Изготовлено</div>
                <div>{order.finishDate ? order.finishDate.toLocaleDateString() : ""}</div>
                <div className="font-weight-bold">Размер</div>
                <div>{order.size.name}</div>
                <div className="font-weight-bold">Кол-во</div>
                <div>{order.quantity}</div>
            </td>
            <td  className="col">
                <div className="font-weight-bold">Комплект</div>
                <div>{order.kit.name}</div>
                <div className="font-weight-bold">Основа</div>
                <div>{order.colorBase.name}</div>
                <div className="font-weight-bold">Нагелея</div>
                <div>{order.colorDetails.name}</div>
            </td>
            <td className="col">
                {order.kit.ramps < 1 ? "" :
                    <React.Fragment>
                        <div className="font-weight-bold">Наполнение рампы</div>
                        <div>{order.ramp.name}</div>
                        <div className="font-weight-bold">Основа рампы</div>
                        <div>{order.rampColorBase.name}</div>
                        <div className="font-weight-bold">Детали рампы</div>
                        <div>{order.rampColorDetails.name}</div>
                    </React.Fragment>
                }
            </td>
            <td className="col">
                {order.kit.ramps < 2 ? "" :
                    <React.Fragment>
                        <div className="font-weight-bold">Наполнение рампы 2</div>
                        <div>{order.ramp2.name}</div>
                        <div className="font-weight-bold">Основа рампы 2</div>
                        <div>{order.rampColorBase2.name}</div>
                        <div className="font-weight-bold">Детали рампы 2</div>
                        <div>{order.rampColorDetails2.name}</div>
                    </React.Fragment>
                }
            </td>
            <td className="col">
                <div>
                    <div className="font-weight-bold">№&nbsp;заказа</div>
                    <div>{order.orderNum}&nbsp;</div>
                    <div className="font-weight-bold">Примечание</div>
                    <div>{order.notes}&nbsp;</div>
                </div>
            </td>
            <td className="col">
                <div>
                    <label htmlFor={`trackNum-${order.id}`}>Трек&nbsp;№</label>
                    <input id={`trackNum-${order.id}`} name="trackNum" type="text" value={order.trackNum} className="form-control form-control-sm"
                           onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.value)}/>
                </div>
                <div className="mt-2">
                    <label htmlFor={`deliveryPrice-${order.id}`}>Стоимость&nbsp;доставки</label>
                    <input id={`deliveryPrice-${order.id}`} name="deliveryPrice" type="number" value={order.deliveryPrice} min="0" className="form-control form-control-sm"
                           onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.value)}
                           onBlur={(e) => crudUpdateItemFocusLostAct(order.id, category, [e.target.name], e.target.value)}/>
                </div>
                <div className="mt-2">
                    <label htmlFor={`weight-${order.id}`}>Вес</label>
                    <input id={`weight-${order.id}`} name="weight" type="number" value={order.weight} min="0" className="form-control form-control-sm"
                           onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.value)}
                           onBlur={(e) => crudUpdateItemFocusLostAct(order.id, category, [e.target.name], e.target.value)}/>
                </div>
            </td>
        </tr>
    )
}

const mapStateToProps = () => {return {}}

export default connect(mapStateToProps, actions)(FinishedRow)
