import React from "react";
import {connect} from "react-redux"

import * as actions from "../actions"

const WorkingRow = ({order, category, crudUpdateItemAct}) => {
    return (
        <tr className={`row row-cols-lg-6 row-cols-sm-3 row-cols-2${order.urgent ? " bg-warning" : ""}`}>
            <td className="col">
                <div className="font-weight-bold">Дата</div>
                <div>{order.date ? order.date.toLocaleDateString() : ""}</div>
                <div className="font-weight-bold">Размер</div>
                <div>{order.size.name}</div>
                <div className="font-weight-bold">Кол-во</div>
                <div>{order.quantity}</div>
            </td>
            <td  className="col">
                <div className="font-weight-bold">Комплект</div>
                <div>{order.kit.name}</div>
                <div className="font-weight-bold">Основа</div>
                <div>{order.colorBase.name}</div>
                <div className="font-weight-bold">Нагелея</div>
                <div>{order.colorDetails.name}</div>
            </td>
            <td className="col">
                {order.kit.ramps < 1 ? "" :
                    <React.Fragment>
                        <div className="font-weight-bold">Наполнение рампы</div>
                        <div>{order.ramp.name}</div>
                        <div className="font-weight-bold">Основа рампы</div>
                        <div>{order.rampColorBase.name}</div>
                        <div className="font-weight-bold">Детали рампы</div>
                        <div>{order.rampColorDetails.name}</div>
                    </React.Fragment>
                }
            </td>
            <td className="col">
                {order.kit.ramps < 2 ? "" :
                    <React.Fragment>
                        <div className="font-weight-bold">Наполнение рампы 2</div>
                        <div>{order.ramp2.name}</div>
                        <div className="font-weight-bold">Основа рампы 2</div>
                        <div>{order.rampColorBase2.name}</div>
                        <div className="font-weight-bold">Детали рампы 2</div>
                        <div>{order.rampColorDetails2.name}</div>
                    </React.Fragment>
                }
            </td>
            <td className="col">
                <div>
                    <div className="font-weight-bold">№&nbsp;заказа</div>
                    <div>{order.orderNum}&nbsp;</div>
                    <div className="font-weight-bold">Примечание</div>
                    <div>{order.notes}&nbsp;</div>
                </div>
            </td>
            <td className="col text-right">
                <label htmlFor={`selected-${order.id}`} className="text-nowrap">Выбрать&nbsp;</label>
                <input id={`selected-${order.id}`} name="selected" type="checkbox" checked={order.selected}
                       onChange={(e) => crudUpdateItemAct(order.id, category, [e.target.name], e.target.checked)}/>
            </td>
        </tr>
    )
}

const mapStateToProps = () => {return {}}

export default connect(mapStateToProps, actions)(WorkingRow)
