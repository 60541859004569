import React, {useEffect} from "react";
import {connect} from "react-redux"

import * as actions from "../actions"
import WorkingRow from "./WorkingRow";
import PackingPrint from "./PackingPrint";
import PaintingPrint from "./PaintingPrint";
import PaintingTable from "./PaintingTable";

const WorkingPage = ({orders, workingCalculatePaintingAct, showDrawingTask, ordersMoveToWorkingAct, ordersMoveToFinishAct, controlUpdateAct}) => {
    useEffect(() => {
        workingCalculatePaintingAct();
    }, [workingCalculatePaintingAct, orders]);
    const selected = !orders ? 0 : orders.some(order => order.selected);
    return (
        <main className="container-fluid">
            <div className="col pb-2 d-inline-block">
                <h1>В работе</h1>
            </div>
            <table className="table">
                <tbody>
                {!orders ? <tr><td>&nbsp;</td></tr> : orders.filter(ord => ord.working).map((order, i) => {
                    return <WorkingRow key={i} order={order} category="orders"/>
                })}
                </tbody>
            </table>
            <div className="row">
                <div className="col-6 col-md pb-2 d-inline-block">
                    <PackingPrint/>
                    <PaintingPrint/>
                    <label htmlFor="showDrawingTask" className="text-nowrap">&nbsp;Показать&nbsp;</label>
                    <input id="showDrawingTask" name="showDrawingTask" type="checkbox" checked={showDrawingTask}
                           onChange={(e) => controlUpdateAct([e.target.name], e.target.checked)}/>
                </div>
                <div className="col-6 col-md-auto">
                    <button className="btn btn-primary btn-sm ml-1" disabled={!selected} onClick={() => ordersMoveToWorkingAct(false)}>Отменить</button>
                    <button className="btn btn-primary btn-sm ml-1" disabled={!selected} onClick={ordersMoveToFinishAct}>Изготовлено</button>
                </div>
            </div>
            {showDrawingTask ? <PaintingTable/> : ""}
        </main>
    )
}

const mapStateToProps = (state) => {
    return {orders: state.data.orders, showDrawingTask: state.controls.showDrawingTask};
}

export default connect(mapStateToProps, actions)(WorkingPage);
