
const initialState = {
    loadError: false,
    loaded: false,
    auth: false,
    authData: {
        login: "",
        pass: "",
        loginError: false,
        serverError: false
    },
    submitted: true,

    ref: {},
    data: {},
    paintingRows: [],
    controls: {
        addMaterial: "",
        addMaterialCount: 0,
        plywoodConsumption: 0,
        showDrawingTask: false
    },
    requiredFieldsError: false
};

function reducer(state = initialState, action) {
    // console.log("reducer", action, state.data);
    switch (action.type) {
        case 'LOGIN_DATA_UPDATE':
            return {...state, authData: {...state.authData, serverError: false, loginError: false, [action.prop]: action.value}};
        case 'LOGIN_GET':
            return {...state, auth: action.auth, serverError: false};
        case 'LOGIN_SUCCESS':
            return {...state, authData: {...state.authData, serverError: false, loginError: !action.auth, pass: ""}, auth: action.auth};
        case 'LOGIN_ERROR':
            console.error(action.error);
            return {...state, authData: {...state.authData, serverError: true}};
        case 'SUBMIT_SUCCESS':
            return {...state, auth: action.auth, submitted: action.done};
        case 'SUBMIT_ERROR':
            console.error(action.error);
            return {...state, submitted: false};

        case "ORDERS_REF_LOADED":
            return {...state, ref: action.ref};
        case "ORDERS_LOADED":
            action.data.orders.forEach(order => order.date = new Date(order.date));
            action.data.finishedOrders.forEach(order => order.date = new Date(order.date));
            action.data.finishedOrders.forEach(order => order.finishDate = order.finishDate ? new Date(order.finishDate) : undefined);
            return {...state, data: action.data, controls: {...state.controls, addMaterial: getFirstValue(action.data.materials)}, loadError: false, loaded: true};
        case "ORDERS_LOAD_ERROR":
            console.error(action.dataType + " load error!", action.error);
            return {...state, loadError: true};
        case "ORDERS_ADD_ROW": {
            const newRow = {id: state.data.nextOrdersRowId + 1, date: new Date(), kit: getFirstValue(state.ref.kits), quantity: 1, colorBase: getFirstValue(state.ref.colorsBase),
                colorDetails: getFirstValue(state.ref.colorsDetails), size: getFirstValue(state.ref.sizes), ramp: getFirstValue(state.ref.ramps),
                ramp2: getFirstValue(state.ref.ramps), rampColorBase: getFirstValue(state.ref.colorsBase), rampColorDetails: getFirstValue(state.ref.colorsDetails),
                rampColorBase2: getFirstValue(state.ref.colorsBase), rampColorDetails2: getFirstValue(state.ref.colorsDetails), notes: "", orderNum: "", trackNum: "", deliveryPrice: 0, weight: 0, urgent: false, working: false, selected: false};
            return {...state, data: {...state.data, nextOrdersRowId: state.data.nextOrdersRowId + 1, orders: [...state.data.orders, newRow]}, submitted: false};
        }
        case 'CRUD_DELETE': {
            const ind = state.data[action.category].findIndex(it => it.id === action.id);
            const items = [...state.data[action.category].slice(0, ind), ...state.data[action.category].slice(ind + 1)];
            return {...state, data: {...state.data, [action.category]: items}, submitted: false};
        }
        case 'CRUD_UPDATE': {
            let {id, category, prop, value, dataList} = action;
            if (prop[0] === 'date') {
                value = value ? new Date(value) : "";
            } else if (value !== "" && !isNaN(value)) {
                value = +value;
            } else if (dataList) {
                value = dataList[dataList.findIndex(it => it.name === value)];
            }
            const ind = state.data[category].findIndex(it => it.id === id);
            const item = state.data[category][ind];
            const newItem = {...item, [prop]: value};
            const items = [...state.data[category].slice(0, ind), newItem, ...state.data[category].slice(ind + 1)];
            return {...state, data: {...state.data, [category]: items}, submitted: false};
        }
        case 'CRUD_UPDATE2': {
            let {idField, id, category, prop, value, dataList} = action;
            if (prop[0] === 'date') {
                value = value ? new Date(value) : "";
            } else if (value !== "" && !isNaN(value)) {
                value = +value;
            } else if (dataList) {
                value = dataList[dataList.findIndex(it => it.name === value)];
            }
            const ind = state.data[category].findIndex(it => it[idField] === id);
            const item = state.data[category][ind];
            const newItem = {...item, [prop]: value};
            const items = [...state.data[category].slice(0, ind), newItem, ...state.data[category].slice(ind + 1)];
            return {...state, data: {...state.data, [category]: items}, submitted: false};
        }
        case 'CRUD_UPDATE_FOCUS_LOST': {
            let {id, category, prop, value, defaultValue} = action;
            value = (value !== "" && !isNaN(value)) ? +value : (defaultValue ? defaultValue : 0);
            const ind = state.data[category].findIndex(it => it.id === id);
            const item = state.data[category][ind];
            const newItem = {...item, [prop]: value};
            const items = [...state.data[category].slice(0, ind), newItem, ...state.data[category].slice(ind + 1)];
            return {...state, data: {...state.data, [category]: items}, submitted: false};
        }
        case 'CONTROL_UPDATE': {
            let {prop, value, dataList} = action;
            value = (value !== "" && !isNaN(value)) ? +value : value;
            if (dataList) {
                value = dataList[dataList.findIndex(it => it.name === value)];
            }
            return {...state, controls: {...state.controls, [prop]: value}};
        }
        case 'CONTROL_FOCUS_LOST': {
            let {prop, value, defaultValue} = action;
            value = (value !== "" && !isNaN(value)) ? +value : (defaultValue ? defaultValue : 0);
            return {...state, controls: {...state.controls, [prop]: value}};
        }
        case "ORDERS_REMOVE": {
            const restOrders = state.data.orders.filter(it => !it.selected);
            return {...state, data: {...state.data, orders: restOrders}, submitted: false};
        }
        case "ORDERS_MOVE_TO_WORKING": {
            if (state.data.orders.some(it => it.selected && !it.orderNum)) {
                return {...state, requiredFieldsError: true};
            }
            const orders = state.data.orders.map(it => (it.selected ? {...it, working: action.isWorking, selected: false} : it));
            return {...state, data: {...state.data, orders: orders}, requiredFieldsError: false, submitted: false};
        }
        case "ORDERS_REMOVE_FINISHED": {
            const restOrders = state.data.finishedOrders.filter(it => it.date.getMonth() !== action.month);
            return {...state, data: {...state.data, finishedOrders: restOrders}, submitted: false};
        }
        case "ORDERS_MOVE_TO_FINISH": {
            const restOrders = state.data.orders.filter(it => !it.selected);
            const finishedOrders = state.data.orders.filter(it => it.selected).map(ord => {return {...ord, working: false, selected: false, finishDate: new Date()}});
            const expenses = {};
            const fillExpenses = (expenses, materialsObj, sizeName) => {
                if (materialsObj) {
                    for (const materialName in materialsObj) {
                        const value = (typeof materialsObj[materialName] === "number") ? materialsObj[materialName] : materialsObj[materialName][sizeName];
                        expenses[materialName] = (expenses[materialName]) ? expenses[materialName] + value : value;
                    }
                }
            };
            for (const order of finishedOrders) {
                fillExpenses(expenses, order.kit.materials, order.size.name);
                if (order.kit.ramps > 0) {
                    fillExpenses(expenses, order.ramp.materials, order.size.name);
                }
                if (order.kit.ramps > 1) {
                    fillExpenses(expenses, order.ramp2.materials, order.size.name);
                }
            }
            console.log("Расход", Object.keys(expenses).map(key => key + " = " + expenses[key]));
            const materials = state.data.materials.map(material => {
                // return expenses[material.name] ? {...material, count: material.count - expenses[material.name]} : material;
                const count = expenses[material.name] ? expenses[material.name] : 0;
                delete expenses[material.name];
                return count !== 0 ? {...material, count: material.count - count} : material;
            });
            if (Object.keys(expenses).length > 0) {
                console.error("Materials not found: ", expenses);
            }
            // return {...state, data: {...state.data, orders: restOrders, finishedOrders: [...state.data.finishedOrders, finishedOrders]}};
            return {...state, data: {...state.data, orders: restOrders, finishedOrders: state.data.finishedOrders.concat(finishedOrders), materials: materials}, submitted: false};
        }
        case "WORKING_CALCULATE_PAINTING":
            return {...state, paintingRows: calculate(state.data.orders)};
        case "MATERIALS_ADD": {
            const ind = state.data.materials.findIndex(it => it.name === state.controls.addMaterial.name);
            const item = state.data.materials[ind];
            const newItem = {...item, count: item.count + state.controls.addMaterialCount};
            const items = [...state.data.materials.slice(0, ind), newItem, ...state.data.materials.slice(ind + 1)];
            return {...state, data: {...state.data, materials: items}, controls: {...state.controls, addMaterialCount: 0}, submitted: false};
        }
        case "PLYWOOD_CONSUMPTION": {
            return {...state, controls: {...state.controls, plywoodConsumption: 0}, submitted: false, data: {...state.data, materials: state.data.materials.map(material => {
                if (material["plywood"]) {
                    return {...material, count: material.count - state.controls.plywoodConsumption};
                } else if (material["usePlywood"]) {
                    let newPlaywoodUsed = material["plywoodUsed"] + state.controls.plywoodConsumption;
                    let newCount = material["count"];
                    if (newPlaywoodUsed >= material["usePlywood"]) {
                        newCount -= Math.floor(newPlaywoodUsed / material["usePlywood"]);
                        newPlaywoodUsed %= material["usePlywood"];
                    }
                    return {...material, count: newCount, plywoodUsed: newPlaywoodUsed};
                }
                return material;
            })}};
        }
        default:
            if (!action.type.includes("@@redux/INIT")) {
                console.error("Unknown action type: " + action.type, action);
            }
            return state;
    }
}

const getFirstValue = (dataArray) => Array.isArray(dataArray) ? dataArray.find(d => !d.invisible) : Object.keys(dataArray)[0];

function calculate(orders) {
    const incrGrouping = (arr, key, count = 1) => arr[key] = arr[key] ? arr[key] + count : count;
    const triangles = {}, archs = {}, ramps = {}, nogs660 = {}, nogs530 = {}, taps = {}, hooks = {}, details = {};
    orders = orders ? orders.filter(ord => ord.working) : [];
    for (const order of orders) {
        if (order.kit.triangle) {
            incrGrouping(triangles, order.colorBase.name, order.quantity);
            incrGrouping(taps, order.colorDetails.tapsHooks, order.quantity * 2);
            for (let color in order.size[order.colorDetails.name]["660"]) {
                const realColor = (color === "nogReplace") ? order.colorBase.nogReplace : color;
                incrGrouping(nogs660, realColor, order.quantity * order.size[order.colorDetails.name]["660"][color]);
            }
        }
        if (order.kit.arch) {
            incrGrouping(archs, order.colorBase.name, order.quantity);
            for (let color in order.size[order.colorDetails.name]["530"]) {
                const realColor = (color === "nogReplace") ? order.colorBase.nogReplace : color;
                incrGrouping(nogs530, realColor, order.quantity * order.size[order.colorDetails.name]["530"][color]);
            }
        }
        if (order.kit.ramps > 0) {
            incrGrouping(ramps, order.rampColorBase.name, order.quantity);
            incrGrouping(hooks, order.rampColorDetails.tapsHooks, order.quantity * 4);
            for (let i = 0; i<order.ramp[order.rampColorDetails.name].length; ++i) {
                details[order.ramp.detailsName + (i+1)] = (details[order.ramp.detailsName + (i+1)]) ? details[order.ramp.detailsName + (i+1)] : {};
                const color = (order.ramp[order.rampColorDetails.name][i] === "detailReplace") ? order.rampColorBase.detailReplace : order.ramp[order.rampColorDetails.name][i];
                incrGrouping(details[order.ramp.detailsName + (i+1)], color, order.quantity);
            }
        }
        if (order.kit.ramps > 1) {
            incrGrouping(ramps, order.rampColorBase2.name, order.quantity);
            incrGrouping(hooks, order.rampColorDetails2.tapsHooks, order.quantity * 4);
            for (let i = 0; i<order.ramp2[order.rampColorDetails2.name].length; ++i) {
                details[order.ramp2.detailsName + (i+1)] = (details[order.ramp2.detailsName + (i+1)]) ? details[order.ramp2.detailsName + (i+1)] : {};
                const color = (order.ramp2[order.rampColorDetails2.name][i] === "detailReplace") ? order.rampColorBase2.detailReplace : order.ramp2[order.rampColorDetails2.name][i];
                incrGrouping(details[order.ramp2.detailsName + (i+1)], color, order.quantity);
            }
        }
    }

    const resultRows = [];
    fillResultRows(resultRows, triangles, "Треугольники");
    fillResultRows(resultRows, archs, "Арки");
    fillResultRows(resultRows, ramps, "Рампы");
    fillResultRows(resultRows, nogs660, "Нагеля 660");
    fillResultRows(resultRows, nogs530, "Нагеля 530");
    fillResultRows(resultRows, taps, "Краники");
    fillResultRows(resultRows, hooks, "Зацепы");
    for (const det in details) {
        fillResultRows(resultRows, details[det], det);
    }
    return resultRows;
}

const fillResultRows = (resultRows, map, name) => {
    for (const color in map) {
        resultRows.push({"name": name, "color": color, "count": map[color]})
    }
};

export default reducer;