import React from "react";
import {connect} from "react-redux"

import * as actions from "../actions"
import FinishedRow from "./FinishedRow";
import MonthReportPrint from "./MonthReportPrint";

const FinishedPage = ({orders, ordersRemoveFinishedAct}) => {
    let monthOrders = [];
    let monthString = "";
    if (orders && orders.length > 0) {
        monthString = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"][orders[0].date.getMonth()];
        monthOrders = orders.filter(order => order.date.getMonth() === orders[0].date.getMonth());
    }
    return (
        <main className="container-fluid">
            <div className="col pb-2 d-inline-block">
                <h1>Изготовлено</h1>
            </div>
            <table className="table">
                <tbody>
                {!orders ? <tr><td>&nbsp;</td></tr> : orders.map((order, i) => {
                    return <FinishedRow key={i} order={order} category="finishedOrders"/>
                })}
                </tbody>
            </table>
            <div className="row">
                <div className="col-6 col-md pb-2 d-inline-block mt-1">
                    {!orders || orders.length === 0 ? "" :
                        <button className="btn btn-danger btn-sm text-nowrap" onClick={() => ordersRemoveFinishedAct(orders[0].date.getMonth())}>Удалить данные за {monthString}</button>
                    }
                </div>
                <div className="col-6 col-md-auto">
                    <MonthReportPrint orders={monthOrders} monthString={monthString}/>
                </div>
            </div>
        </main>
    )
}

const mapStateToProps = (state) => {
    return {orders: state.data.finishedOrders};
}

export default connect(mapStateToProps, actions)(FinishedPage)
