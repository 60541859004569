import React from "react";

export default class ErrorHandler extends React.Component {
    state = {
        isError: false,
        error: "",
        errorInfo: ""
    }


    componentDidCatch(error, errorInfo) {
        this.setState(state => {
            return {isError: true, error: error, errorInfo: errorInfo};
        });
    }

    render() {
        if (this.state.isError) {
            return (
                <main>
                    <div>Ошибка!</div>
                    <div>{this.state.error}</div>
                    <div>{this.state.errorInfo}</div>
                </main>
            );
        } else {
            return this.props.children;
        }
    }
}