import React from "react";
import {connect} from "react-redux"
import * as actions from "../actions";

const PaintingRow = ({row}) => {
    return (
        <tr>
            <td>
                <div>{row.name}</div>
            </td>
            <td>
                <div>{row.color}</div>
            </td>
            <td>
                <div>{row.count}</div>
            </td>
        </tr>
    );
};

const PaintingTable = ({rows}) => {
    return (
        <React.Fragment>
            {!rows ? "" :
                <table className="table table-bordered">
                    <thead>
                    <tr className="table-secondary">
                        <th>Наименование</th>
                        <th>Цвет</th>
                        <th>Кол-во</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows.map((row, index) => {
                        return <PaintingRow key={index} row={row}/>
                    })}
                    </tbody>
                </table>
            }
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {rows: state.paintingRows};
}

export default connect(mapStateToProps, actions)(PaintingTable);
