import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import {Provider} from "react-redux";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import {Font} from "@react-pdf/renderer";
import ApiService from "./apiService";
import reducer from "./reducer";
import ErrorHandler from "./component/ErrorHandler";
import ApiServiceContext from "./ApiServiceContext";
import App from './component/App';

Font.register({family: "Roboto", src: "roboto-medium-webfont.ttf"});
const store = createStore(reducer, applyMiddleware(thunk));
const apiService = new ApiService();

ReactDOM.render(
    <Provider store={store}>
        <ErrorHandler>
            <ApiServiceContext.Provider value={apiService}>
                <App />
            </ApiServiceContext.Provider>
        </ErrorHandler>
    </Provider>,
    document.getElementById('root')
);
