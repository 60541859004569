import Axios from "axios";

export default class ApiService {

    async getResource (url) {
        const resp = await fetch(url);
        // console.log(resp);
        if (!resp.ok) throw new Error(`Error ${resp.status} for ${url}`);
        return await resp.json();
    };

    getRef() {
        return this.getResource(`${window.PUBLIC_URL}/ref.json`).then(json => {
            return json;
        });
    }

    getData() {
        return this.getResource(`${window.PUBLIC_URL}/data.json`).then(json => {
            return json;
        });
    }

    getIsLogined() {
        return Axios.get(`${window.PUBLIC_URL}/auth.php`)
    }
    doLogin(login, pass) {
        return Axios.post(`${window.PUBLIC_URL}/auth.php`, {"login": login, "pass": pass})
    }
    submitChanges(data) {
        return Axios.post(`${window.PUBLIC_URL}/upd.php`, data);
    }
    closeSession(login) {
        return Axios.post(`${window.PUBLIC_URL}/auth.php`, {"login": login, "close": true})
    }
}