import React, {useContext, useEffect} from "react";
import {connect} from "react-redux";
import {BrowserRouter, Route, Switch, Redirect, NavLink} from 'react-router-dom'

import ApiServiceContext from "../ApiServiceContext";
import * as actions from "../actions";
import Login from "./Login";
import OrdersPage from "./OrdersPage";
import WorkingPage from "./WorkingPage";
import FinishedPage from "./FinishedPage";
import MaterialsPage from "./MaterialsPage";

const App = ({loadError, loaded, auth, data, submitted, loginGetAct, loginErrorAct, ordersRefLoadedAct, ordersLoadedAct, ordersLoadErrorAct, submitChangesAct}) => {
    const apiService = useContext(ApiServiceContext);
    useEffect(() => {
        apiService.getRef().then(ref => {
            ordersRefLoadedAct(ref);
            apiService.getData().then(data => {
                ordersLoadedAct(data);
                apiService.getIsLogined()
                    .then(resp => loginGetAct(resp.data.auth))
                    .catch(error => loginErrorAct(error));
            }).catch(error => ordersLoadErrorAct("data", error))
        }).catch(error => ordersLoadErrorAct("ref", error))
    }, [apiService, loginGetAct, loginErrorAct, ordersRefLoadedAct, ordersLoadedAct, ordersLoadErrorAct]);
    if (!auth) {
        return <Login/>;
    } else if (loadError) {
        return <div className="text-danger mx-3">Ошибка получения данных сервера!</div>;
    } else if (!loaded) {
        return <div className="mx-3">Загрузка...</div>;
    }
    return (
        <BrowserRouter>
            <div className="bg-light">
                {submitted ? " " : <button className="btn btn-danger btn-sm ml-1 mt-1" onClick={() => submitChangesAct(data, apiService)}>Сохранить изменения</button>}
            </div>
            <nav className="pt-3 bg-light">
                <ul className="nav justify-content-center nav-tabs">
                    <li className="nav-item">
                        <NavLink exact to="/" className="nav-link " style={{color: "#343a40"}} activeClassName="text-secondary font-weight-bold active">Заказы</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact to="/working" className="nav-link" style={{color: "#343a40"}} activeClassName="text-secondary font-weight-bold active" activeStyle={{color: "#ffc107"}}>В работе</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact to="/finished" className="nav-link" style={{color: "#343a40"}} activeClassName="text-secondary font-weight-bold active" activeStyle={{color: "#ffc107"}}>Изготовлено</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact to="/materials" className="nav-link" style={{color: "#343a40"}} activeClassName="text-secondary font-weight-bold active" activeStyle={{color: "#ffc107"}}>Материалы</NavLink>
                    </li>
                </ul>
            </nav>
            <Switch>
                <Route exact path="/" component={OrdersPage}/>
                <Route exact path="/working" component={WorkingPage}/>
                <Route exact path="/finished" component={FinishedPage}/>
                <Route exact path="/materials" component={MaterialsPage}/>
                <Redirect to="/"/>
            </Switch>
        </BrowserRouter>
    );
}

const mapStateToProps = ({loadError, loaded, auth, data, submitted}) => {
    return {loadError, loaded, auth, data, submitted};
}

export default connect(mapStateToProps, actions)(App);
