import React from "react";
import {connect} from "react-redux"
import {
    pdf,
    Document,
    Page,
    Text,
    View,
    // PDFViewer,
    StyleSheet
} from "@react-pdf/renderer";
import { saveAs } from 'file-saver';

import * as actions from "../actions"

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        fontSize: 10,
    },
    header: {
        fontSize: 16,
        marginTop: "10px",
        textAlign: "center"
    },
    table: {
        display: "table",
        width: "auto",
        marginLeft: "10px",
        marginTop: "10px",
        borderStyle: "solid",
        borderLeftWidth: 1,
    },
    tableRow: {
        flexDirection: "row",
    },
    dataHeader: {
        textAlign: "center",
        backgroundColor: "#ccc",
        padding: 2,
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 1
    },
    dataCol: {
        padding: 2,
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    }
});

const MonthReportPdfDocument = ({rows, monthString, totalCost}) => {
    return (!rows ? "" :
            <Document>
                <Page size="A4" orientation="portrait" style={styles.page}>
                    <View style={styles.header}>
                        <Text>Отчет за {monthString}</Text>
                    </View>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={{...styles.dataHeader, width: "30px"}}>№</Text>
                            <Text style={{...styles.dataHeader, width: "340px"}}>Наименование</Text>
                            <Text style={{...styles.dataHeader, width: "100px"}}>Кол-во</Text>
                            <Text style={{...styles.dataHeader, width: "100px"}}>Стоимость (грн)</Text>
                        </View>
                        {rows.map((row, index) => {
                            return (
                                <View key={index} style={styles.tableRow}>
                                    <Text style={{...styles.dataCol, width: "30px"}}>{index + 1}</Text>
                                    <Text style={{...styles.dataCol, width: "340px"}}>{row.name}</Text>
                                    <Text style={{...styles.dataCol, width: "100px"}}>{row.quantity}</Text>
                                    <Text style={{...styles.dataCol, width: "100px"}}>{!row.cost && row.cost !== 0 ? "" : row.cost.toFixed(2)}</Text>
                                </View>
                            );
                        })}
                    </View>
                    <Text style={{textAlign: "right", marginTop: "10px", marginRight: "20px"}}>Общая стоимость: {totalCost.toFixed(2)} грн</Text>
                </Page>
            </Document>
    );
};

function calculateMonthReportRows(orders, materials) {
    let result = Object.values(orders.reduce((resObj, order) => {           // kits
        const key = order.kit.name + " (" + order.size.name + ")";
        resObj[key] = (!resObj[key]) ? {name: key, quantity: 1} : {...resObj[key], quantity: resObj[key].quantity + 1};
        return resObj;
    }, {}));

    const expenses = {};                        // materials
    const fillExpenses = (expenses, materialsObj, sizeName) => {
        if (materialsObj) {
            for (const materialName in materialsObj) {
                const value = (typeof materialsObj[materialName] === "number") ? materialsObj[materialName] : materialsObj[materialName][sizeName];
                expenses[materialName] = (expenses[materialName]) ? expenses[materialName] + value : value;
            }
        }
    };
    for (const order of orders) {
        fillExpenses(expenses, order.kit.materials, order.size.name);
        if (order.kit.ramps > 0) {
            fillExpenses(expenses, order.ramp.materials, order.size.name);
        }
        if (order.kit.ramps > 1) {
            fillExpenses(expenses, order.ramp2.materials, order.size.name);
        }
    }
    const getMaterialCost = (materialName) => {
        const mat = materials.find(material => material.name === materialName);
        return mat ? mat.price : 0;
    }
    result.push(...Object.keys(expenses).map(key => {return {name: key, quantity: expenses[key], cost: getMaterialCost(key) * expenses[key]}}));

    // result.push({name: "asdf", quantity: 123, cost: 234})
    // result.push({name: "sdfg", quantity: 456, cost: 567})
    return result;
}

const MonthReportPrint = ({orders, monthString, materials}) => {
    if (orders && orders.length > 0) {
        const rows = calculateMonthReportRows(orders, materials);
        return (
            <React.Fragment>
                {/*<PDFViewer style={{width: "1400px", height: "900px"}}>*/}
                {/*    <PackingPdfDocument orders={orders} date={date}/>*/}
                {/*</PDFViewer>*/}
                <button className="btn btn-primary btn-sm ml-1 mt-1 mb-2" onClick={async () => {
                    const doc = <MonthReportPdfDocument rows={rows} monthString={monthString} totalCost={rows.reduce((init, row) => init + (row.cost ? row.cost : 0), 0)}/>
                    const asPdf = pdf([]);
                    asPdf.updateContainer(doc);
                    const blob = await asPdf.toBlob();
                    saveAs(blob, `Отчет за ${monthString}.pdf`);
                }}>{`${monthString.length > 0 ? "Отчет за " + monthString : "Месячный отчет"}`}</button>
            </React.Fragment>
        )
    }
    return <button className="btn btn-primary btn-sm ml-1 mt-1 mb-2" disabled={true}>{`${monthString.length > 0 ? "Отчет за " + monthString : "Месячный отчет"}`}</button>
}

const mapStateToProps = (state) => {
    return {materials: state.data.materials}
};

export default connect(mapStateToProps, actions)(MonthReportPrint);
