import React, {useContext} from "react";
import {connect} from 'react-redux'
import * as actions from '../actions'
import ApiServiceContext from "../ApiServiceContext";

const Login = ({authData, loginDataUpdateAct, loginClickAct}) => {
    const apiService = useContext(ApiServiceContext);
    return (
        <div className="d-flex justify-content-center text-right mt-5">
            <form onSubmit={(e) => {e.preventDefault(); return loginClickAct(authData, apiService)}}>
                <table>
                    <tbody>
                    <tr>
                        <td>
                            <div className="mb-1">Логин: </div>
                        </td>
                        <td>
                            <input type="text" id="login" name="login" className="mb-1" value={authData.login} required onChange={(e) => loginDataUpdateAct([e.target.name], e.target.value)}/>
                        </td>
                    </tr>
                    <tr className="mb-1">
                        <td>
                            <div className="mb-1">Пароль: </div>
                        </td>
                        <td>
                            <input type="password" id="pass" name="pass" className="mb-1" value={authData.pass} required onChange={(e) => loginDataUpdateAct([e.target.name], e.target.value)}/>
                        </td>
                    </tr>
                    <tr><td colSpan="2">
                        <button type="Submit" className="btn btn-dark mb-1">Войти</button>
                    </td></tr>
                    </tbody>
                </table>
                {authData.loginError ? <div className="alert alert-danger text-center" role="alert">Ошибка! Неверный логин или пароль</div> : ""}
                {authData.serverError ? <div className="alert alert-danger text-center" role="alert">Ошибка! Cервер не доступен!</div> : ""}
            </form>
        </div>
    );
};

const mapStateToProps = ({authData}) => {
    return {authData};
};
export default connect(mapStateToProps, actions)(Login);